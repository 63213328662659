.branding-name {
  height: fit-content;
  width: fit-content;
  color: #fff;
}
.tracks-header-desktop {
  position: fixed;
  height: 55px;
  width: 100vw;
  background-color: #1e314e;
  z-index: 4;
}
.tracks-header-mobile {
  position: fixed;
  height: 55px;
  width: 100vw;
  background-color: #1e314e;
  z-index: 4;
}
.branding-name,
.page-title {
  position: absolute;
  height: fit-content;
  width: fit-content;
  margin: auto;
  right: 0;
  left: 0;
  top: 15px;
}
.page-title {
  font-size: 2.5rem;
  bottom: 40px;
}
.theme-switch {
  position: absolute;
  margin: auto !important;
  right: 5px;
  top: 2px;
}

.chips {
  position: absolute;
  height: 80px;
  width: 100vw;
  margin: auto;
  top: 70px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0 20px;
}

.tracks-container {
  position: absolute;
  height: fit-content;
  width: 100%;
  margin: auto;
  top: 130px;
  left: 0;
  right: 0;
}

.track {
  padding: 0 20px;
  text-align: center;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.service-illustration {
  height: 175px;
  width: 175px;
}

.contact-container > * {
  flex: 1;
}

.contact-illustration {
  min-width: 400px;
  height: 100%;
  padding: 2rem;
}

@media (max-width: 848px) {
  .track {
    grid-template-columns: 1fr;
  }
  .tracks-header-desktop {
    display: none;
  }
  .quote-container {
    padding: 2rem !important;
  }
  .quote-text {
    width: 100% !important;
  }
  .hero-text {
    font-size: 1.2rem !important;
    margin: 1rem !important;
    width: 90% !important;
  }
  .section-container {
    padding: 2rem 0.5rem !important;
  }
  .banner-container {
    padding: 3rem 2rem !important;
  }
  .banner-content {
    width: 100% !important;
  }
  .contact-illustration {
    margin-bottom: 1.5rem;
  }
  .contribute-container {
    padding: 3rem 1rem !important;
  }
  .contribute-content {
    padding: 1rem !important;
  }
  .contribute-content h1 {
    font-size: 1.1rem !important;
  }
  .contribute-content h6 {
    font-size: 0.8rem !important;
  }
  .contribute-alert {
    padding: 1rem !important;
  }
  .contribute-submit {
    padding: 0.5rem 2.5rem !important;
    margin: 1rem 0.5rem 1.5rem 0.5rem !important;
  }
  .contribute-cancel {
    padding: 0.5rem 2.5rem !important;
    margin: 1rem 0.5rem 1.5rem 0.5rem !important;
  }
}

@media (min-width: 848px) {
  .tracks-header-mobile {
    display: none;
  }
}
