@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-user-drag: none;
  text-decoration: none;
}

::-webkit-scrollbar {
  appearance: none;
  background-color: #1E314E;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #3A4BDF;
}

.chips::-webkit-scrollbar {
  display: none;
}

@media (max-width:870px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1D203B;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

highL,
.highL {
  color: #3A4BDF;
}

span.MuiBottomNavigationAction-label {
  font-family: 'Poppins', sans-serif !important;
}


.MuiGrid-spacing-xs-3>.MuiGrid-item:nth-child(odd) {
  padding-left: 34px !important;
}

a {
  text-decoration: none !important;
  color: inherit
}



@media (max-width:710px) {
  .contributeSubmit {
    width: 100px !important;
    margin-left: 30% !important;
  }

  .contributeCancel {
    width: 100px !important;
  }

  .MuiGrid-spacing-xs-3>.MuiGrid-item:nth-child(even) {
    padding-left: 34px !important;
  }

  .container {
    padding: 0 !important;
  }
}

@media (max-width:430px) {
  .contributeSubmit {
    width: 100px !important;
    margin-left: 25% !important;
  }
}

@media (max-width: 992px) {
  .MuiGrid-spacing-xs-3>.MuiGrid-item:nth-child(even) {
    padding-left: 34px !important;
  }
}