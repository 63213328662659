@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  -webkit-user-drag: none;
  text-decoration: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
          appearance: none;
  background-color: #1E314E;
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #3A4BDF;
}

.chips::-webkit-scrollbar {
  display: none;
}

@media (max-width:870px) {
  ::-webkit-scrollbar {
    display: none;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #1D203B;
  overflow-x: hidden;
  overflow-y: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

highL,
.highL {
  color: #3A4BDF;
}

span.MuiBottomNavigationAction-label {
  font-family: 'Poppins', sans-serif !important;
}


.MuiGrid-spacing-xs-3>.MuiGrid-item:nth-child(odd) {
  padding-left: 34px !important;
}

a {
  text-decoration: none !important;
  color: inherit
}



@media (max-width:710px) {
  .contributeSubmit {
    width: 100px !important;
    margin-left: 30% !important;
  }

  .contributeCancel {
    width: 100px !important;
  }

  .MuiGrid-spacing-xs-3>.MuiGrid-item:nth-child(even) {
    padding-left: 34px !important;
  }

  .container {
    padding: 0 !important;
  }
}

@media (max-width:430px) {
  .contributeSubmit {
    width: 100px !important;
    margin-left: 25% !important;
  }
}

@media (max-width: 992px) {
  .MuiGrid-spacing-xs-3>.MuiGrid-item:nth-child(even) {
    padding-left: 34px !important;
  }
}
.branding-name {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #fff;
}
.tracks-header-desktop {
  position: fixed;
  height: 55px;
  width: 100vw;
  background-color: #1e314e;
  z-index: 4;
}
.tracks-header-mobile {
  position: fixed;
  height: 55px;
  width: 100vw;
  background-color: #1e314e;
  z-index: 4;
}
.branding-name,
.page-title {
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  right: 0;
  left: 0;
  top: 15px;
}
.page-title {
  font-size: 2.5rem;
  bottom: 40px;
}
.theme-switch {
  position: absolute;
  margin: auto !important;
  right: 5px;
  top: 2px;
}

.chips {
  position: absolute;
  height: 80px;
  width: 100vw;
  margin: auto;
  top: 70px;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  padding: 0 20px;
}

.tracks-container {
  position: absolute;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  margin: auto;
  top: 130px;
  left: 0;
  right: 0;
}

.track {
  padding: 0 20px;
  text-align: center;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
}

.service-illustration {
  height: 175px;
  width: 175px;
}

.contact-container > * {
  flex: 1 1;
}

.contact-illustration {
  min-width: 400px;
  height: 100%;
  padding: 2rem;
}

@media (max-width: 848px) {
  .track {
    grid-template-columns: 1fr;
  }
  .tracks-header-desktop {
    display: none;
  }
  .quote-container {
    padding: 2rem !important;
  }
  .quote-text {
    width: 100% !important;
  }
  .hero-text {
    font-size: 1.2rem !important;
    margin: 1rem !important;
    width: 90% !important;
  }
  .section-container {
    padding: 2rem 0.5rem !important;
  }
  .banner-container {
    padding: 3rem 2rem !important;
  }
  .banner-content {
    width: 100% !important;
  }
  .contact-illustration {
    margin-bottom: 1.5rem;
  }
  .contribute-container {
    padding: 3rem 1rem !important;
  }
  .contribute-content {
    padding: 1rem !important;
  }
  .contribute-content h1 {
    font-size: 1.1rem !important;
  }
  .contribute-content h6 {
    font-size: 0.8rem !important;
  }
  .contribute-alert {
    padding: 1rem !important;
  }
  .contribute-submit {
    padding: 0.5rem 2.5rem !important;
    margin: 1rem 0.5rem 1.5rem 0.5rem !important;
  }
  .contribute-cancel {
    padding: 0.5rem 2.5rem !important;
    margin: 1rem 0.5rem 1.5rem 0.5rem !important;
  }
}

@media (min-width: 848px) {
  .tracks-header-mobile {
    display: none;
  }
}

.about {
  padding: 4rem;
}
.about-banner {
  margin-top: 2rem;
  border-radius: 12px;
  display: flex;
  background: #1e314e;
  padding: 2rem 3rem;
  align-items: center;
}
.about-banner svg {
  height: 300px;
  width: 300px;
}
.about-banner-text {
  padding: 0 3rem;
}
.about-banner-text h1 {
  color: white;
  font-weight: 600;
  font-size: 2rem;
  margin-bottom: 0.75rem;
}
.about-banner-text p {
  color: #99b2d8;
  font-size: 1.1rem;
  margin-bottom: 1.5rem;
}
.about-container {
  margin: 3rem 0 2rem 0;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}
.about-container > * {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}
.about-container-left h3,
.about-container-right h3 {
  color: white;
  text-transform: uppercase;
  margin-bottom: 0.75rem;
  font-weight: 600;
}
.about-container-left p {
  color: #99b2d8;
  margin-bottom: 0.5rem;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
}
.about-container-right > * {
  margin-bottom: 1.5rem;
}
.about-container-right p {
  color: #99b2d8;
  font-size: 1rem;
  padding-right: 5rem;
}
.about-team-container {
  margin: 1.5rem 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px;
  gap: 30px;
}
.about-join {
  text-align: center;
  margin: 4rem 0 2rem 0;
}
.about-join h1 {
  color: white;
  margin-bottom: 0.5rem;
}
.about-join p {
  color: #99b2d8;
  margin-bottom: 1rem;
}

@media (max-width: 848px) {
  .about {
    padding: 4rem 1.5rem;
  }
  .about-banner {
    padding: 2rem 0;
    flex-wrap: wrap;
    justify-content: center;
  }
  .about-banner svg {
    margin-bottom: 1.5rem;
  }
  .about-banner-text h1 {
    font-size: 1.5rem;
  }
  .about-banner-text p {
    font-size: 0.9rem;
  }
  .about-banner-text button {
    display: block;
    margin: 0 auto;
  }
  .about-container > *:not(:last-child) {
    min-width: 100%;
    margin-bottom: 1.5rem;
  }
  .about-container-right p {
    padding: 0;
  }
  .team-card {
    flex-direction: row !important;
    padding: 1rem !important;
    justify-content: flex-start !important;
    align-items: center !important;
  }
  .team-card img {
    width: 35%;
    margin-right: 1rem;
  }
  .team-card h1 {
    margin-top: 0 !important;
    margin-bottom: 0.1rem !important;
  }
  .team-card div {
    text-align: left;
  }
  .team-socials {
    justify-content: flex-start !important;
  }
  .team-card svg {
    margin: 0.75rem 1rem 0 0 !important;
  }
  .about-team-container {
    grid-gap: 20px;
    gap: 20px;
  }
}

